<template>
  <div class="resize">
    <div class="l-col">
      <base-input
        v-model="formData.diskName"
        :placeholder="$t('disk.placeholder')"
        :label="$t('disk.label')"
        class="form__field--input"
        @input="onChange('name', $event)"
      >
      </base-input>
    </div>
    <div class="l-col">
      <!--      <label class="typo__label">{{ $t('label.disk') }}</label>-->
      <base-select
        v-model="disk"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :options="options"
        :field-label="$t('label.disk')"
        track-by="from"
        :custom-label="nameDiskFrom"
        size="medium"
        :allow-empty="false"
        :preselect-first="true"
        class="new-disk__services"
        @input="onChange('disk', $event)"
      >
      </base-select>
    </div>

    <div v-if="disk.from === 'snapshot'" class="l-col">
      <!--      <label class="typo__label">{{ $t('label.snap') }}</label>-->
      <base-select
        v-model="snap"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :field-label="$t('label.snap')"
        :options="snapshots"
        placeholder="Выберите снимок"
        size="medium"
        :custom-label="nameSnapshot"
        track-by="name"
        :allow-empty="false"
        :preselect-first="true"
        class="new-disk__services"
        @input="onChange('snap', $event)"
      >
      </base-select>
    </div>
    <div v-if="disk.from === 'image'" class="l-col">
      <!--      <label class="typo__label">{{ $t('label.image') }}</label>-->
      <base-select
        v-model="image"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :field-label="$t('label.image')"
        :options="images"
        placeholder="Выберите образ"
        size="medium"
        :custom-label="nameImage"
        track-by="name"
        :allow-empty="false"
        class="new-disk__services"
        @input="onChange('image', $event)"
      >
      </base-select>
    </div>
    <div v-if="disk.from === 'disk'" class="l-col">
      <!--      <label class="typo__label">{{ $t('label.image') }}</label>-->
      <base-select
        v-model="volume"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :field-label="$t('label.disks')"
        placeholder="Выберите диск"
        :options="disks"
        label="name"
        track-by="name"
        :custom-label="nameDisk"
        size="medium"
        :allow-empty="false"
        class="new-disk__services"
        @input="onChange('volume', $event)"
      >
      </base-select>
    </div>
    <div v-if="disk.from !== 'snapshot' && disk.from !== 'disk'" class="l-col">
      <!--      <label class="typo__label">{{ $t('label.type') }}</label>-->
      <base-select
        v-model="type"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :field-label="$t('label.type')"
        placeholder="Выберите тип диска"
        :options="typeOfDisk"
        track-by="name"
        :preselect-first="true"
        :custom-label="nameType"
        size="medium"
        :allow-empty="false"
        class="new-disk__services"
        @input="onChange('type', $event)"
      >
      </base-select>
    </div>
    <div class="resize-row">
      <label class="typo__label section-header standart-text">{{
        $t('quota', { quota: quota > formData.size - 1 ? quota - formData.size : quota })
      }}</label>
    </div>
    <div class="resize-row">
      <div class="resize-row-text">
        <label class="standart-title">
          {{ $t('disk.size') }}
        </label>
      </div>
      <div class="resize-row-input">
        <InputNumber
          v-model="sizeDisk"
          show-buttons
          mode="decimal"
          suffix=" ГБ"
          :min="formData.size > 5 ? formData.size : 5"
          :max="quota"
          button-layout="horizontal"
          :input-style="{ 'text-align': 'center', 'max-width': '5rem' }"
          decrement-button-class="p-button-blue"
          increment-button-class="p-button-blue"
          increment-button-icon="pi pi-plus"
          decrement-button-icon="pi pi-minus"
          aria-describedby="value2-help"
          @input="onChange('size', $event)"
        />
      </div>
    </div>

    <div class="resize-row">
      <div v-if="formData.size < size || formData.size > quota" :style="{ 'text-align': 'center' }">
        <label class="typo__label section-header medium-title error-color">{{
          size > formData.size ? $t('quotaDown') : $t('quotaUp')
        }}</label>
      </div>
      <div v-else>
        <label class="typo__label section-header standart-text">
          {{ $t('cost', { msg: $n(cost, 'currency', this.$i18n.locale) }) }}
        </label>
      </div>
    </div>
    <div v-if="isMaxSize" class="resize-row">
      <label class="typo__label section-header medium-title error-color"> {{ $t('upSize') }}</label>
    </div>
    <div v-if="isMaxSize" class="resize-row">
      <label class="typo__label section-header medium-title error-color">{{ $t('upSize2') }}</label>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
import InputNumber from 'primevue/inputnumber';
export default {
  name: 'NewDisk',
  components: { BaseInput, BaseSelect, InputNumber },
  mixins: [setFocus],
  props: {
    quota: {
      type: Number,
      default: 0,
      require: true,
    },
    size: {
      type: Number,
      default: 0,
      require: true,
    },
  },
  data() {
    return {
      value: '',
      volume: [],
      snap: [],
      name: '',
      sizeDisk: 5,
      description: '',
      disk: [],
      type: this.defaultTypeOfDisk,
      image: [],
      formData: {
        diskName: '',
        from: 'none',
        size: this.size,
        name: '',
        id: '',
        type: 'SSD',
        func: '',
      },
    };
  },
  computed: {
    options() {
      return [
        { from: 'none', name: 'чистый' },
        { from: 'snapshot', func: 'snapshot_id', name: 'снимок' },
        { from: 'image', func: 'imageRef', name: 'образ' },
        { from: 'disk', func: 'source_volid', name: 'диск' },
      ];
    },
    isMaxSize() {
      return this.formData.size > 2048;
    },
    price() {
      const type = this.formData.name;
      return type &&
        this.$store.state.moduleStack.price.length > 0 &&
        this.$store.state.moduleStack.price.find(x => x.intname === `volume_${type}`)
        ? this.$store.state.moduleStack.price.find(x => x.intname === `volume_${type}`)[dev]
        : 0;
    },
    cost() {
      return this.formData.size && this.price ? this.formData.size * this.price : 0;
    },
    snapshots() {
      return this.$store.getters['moduleStack/snapshots'].map(x => {
        return {
          name: x.name,
          id: x.id,
          size: x.size,
          func: 'snapshot_id',
        };
      });
    },
    images() {
      return this.$store.getters['moduleStack/images'].map(x => {
        return {
          name: x.name,
          id: x.id,
          size: x.min_disk > 0 ? x.min_disk : Math.ceil(x.size / 1024 ** 3),
          func: 'imageRef',
        };
      });
    },
    disks() {
      return this.$store.getters['moduleStack/disks']
        .filter(x => x.status.code === 15)
        .map(x => {
          return {
            name: x.name,
            id: x.id,
            size: x.size,
            func: 'source_volid',
          };
        });
    },
    typeOfDisk() {
      return this.$store.state.moduleStack.typesOfDisks;
    },
    defaultTypeOfDisk() {
      return this.typeOfDisk.filter(x => x.name === 'SSD');
    },
    // minSizeDisk() {
    //   return this.formData.from === 'none'
    //     ? this.size
    //     : this.formData.size > this.size
    //     ? this.formData.size
    //     : this.size;
    // },
  },
  watch: {
    'formData.size': function (event) {
      // console.log(event, this.formData.size, this.sizeDisk);
      this.sizeDisk =
        event > this.sizeDisk
          ? event
          : this.sizeDisk > this.formData.size
          ? this.sizeDisk
          : this.formData.size > 5
          ? this.formData.size
          : 5;
    },
  },
  methods: {
    // },
    nameDisk({ name, size }) {
      return `${name} (${size}ГБ)`;
    },
    nameSnapshot({ name, size }) {
      return `${name} (${size}ГБ)`;
    },
    nameImage({ name, size }) {
      return `${name} (${size}ГБ)`;
    },
    nameDiskFrom({ name }) {
      return `${name}`;
    },
    nameType({ name }) {
      return `${name}`;
    },
    onChange(type, event) {
      if (event && event.from) {
        this.formData.func = '';
        this.formData.source = '';
        this.formData.id = '';
      }
      if (type === 'image') this.formData.id = event.id;
      if (type === 'volume') this.formData.id = event.id;
      if (type === 'snap') this.formData.id = event.id;
      if (type === 'disk') this.formData.func = event.func;
      if (type === 'type') this.formData.name = event.name;
      if (type === 'name') this.formData.diskName = event;
      if (type === 'size') this.formData.size = event;

      if (this.formData.from === 'none') {
        if (this.formData.diskName && this.formData.size && this.formData.name && this.sizeDisk) {
          return this.$emit('change', {
            name: this.formData.diskName,
            size: this.sizeDisk,
            volume_type: this.formData.name,
          });
        } else return this.$emit('notready');
      } else if (this.formData.from === 'snapshot') {
        if (this.formData.diskName && this.formData.size && this.formData.id && this.sizeDisk) {
          return this.$emit('change', {
            name: this.formData.diskName,
            size: this.sizeDisk,
            snapshot_id: this.formData.id,
          });
        } else return this.$emit('notready');
      } else if (this.formData.from === 'disk') {
        if (this.formData.diskName && this.formData.size && this.formData.id && this.sizeDisk) {
          return this.$emit('change', {
            name: this.formData.diskName,
            size: this.sizeDisk,
            source_volid: this.formData.id,
          });
        } else return this.$emit('notready');
      } else if (this.formData.from === 'image') {
        if (
          this.formData.diskName &&
          this.formData.size &&
          this.formData.id &&
          this.formData.type
        ) {
          return this.$emit('change', {
            name: this.formData.diskName,
            size: this.sizeDisk,
            imageRef: this.formData.id,
            volume_type: this.formData.name,
          });
        } else return this.$emit('notready');
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": {
      "disk": "Источник диска:",
      "type": "Тип:",
      "description": "Описание:",
      "image" : "Использовать образ как источник:",
      "disks" : "Использовать диск как источник:",
      "snap": "Использовать снимок как источник:"
    },
    "disk": {
      "label": "Имя диска:",
      "placeholder": "Имя вашего диска",
      "description" : "Текстовое описание",
      "snap" : "Использовать снимок как источник:",


      "size": "Размер диска"
    },
    "quotaUp": "Размер не может превышать квоту",
    "quotaDown": "Минимальный размер диска - 5 ГБ",
    "upSize": "Диски с разметкой MBR не могут быть больше 2Тб.",
    "upSize2": "Диски больше 2Тб должны быть размечены GPT.",
    "quota": "Доступно по квоте %{quota} ГБ",
    "sure": {
      "confirm": "Увеличить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "cost": "Стоимость диска составит %{msg} / ГБ в час",
    "notice": "Для вступления в силу - перезагрузите сервер",
    "size": "Диски в формате mbr не могут быть больше 2ТБ."
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
